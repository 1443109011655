.staff-availability-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media screen and (max-width: 991px) {
    display: block;
  }
  > div {
    margin: 0;
    > label {
      position: absolute;
    }
  }
  select {
    border: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 15px !important;
    padding-top: 0px !important;
  }
  .view-color-list {
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      margin-right: 10px;
      align-items: center;
      @media screen and (max-width: 1024px) {
        width: 30%;
        margin-bottom: 8px;
      }
      @media screen and (max-width: 575px) {
        width: 50%;
        margin: 0 0 8px;
      }
      .text-box {
        font-weight: 400;
        font-size: 10px;
        color: #87928d;
      }
      .color-box {
        background: #f9fcee;
        border: 1px solid #e5e7df;
        border-radius: 2px;
        width: 12px;
        height: 12px;
        margin-right: 3px;
        &.color-blue {
          background: #e1f4fd;
        }
        &.color-green {
          background: #66accf;
        }
        &.color-pink {
          background: #587e85;
        }
        &.color-orange {
          background: #fef3d7;
        }
        &.color-yellow {
          background: #fcc539;
        }
        &.color-grey {
          background: #f9fcee;
        }
        &.rejected {
          background: #ee4f4f;
        }
        &.color-parrot-green {
          background: #a9cf3d;
        }
      }
    }
  }
}
.link {
  border: none;
  background-color: white;
}
.btn_container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: end;
  @media screen and (max-width: 767px) {
    margin: 20px 0 30px 0 !important;
    justify-content: end;
    flex-direction: column-reverse;
  }
  .prev_next_container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    @media screen and (max-width: 767px) {
      gap: 8px;
      button {
        width: 50%;
      }
    }
  }
}

.break_timesheet_table_header {
  background-color: #f9fcee;
  padding: 16px;
  border-radius: 4px;
  margin-top: 12px;
}
.break_table_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 400px;
  margin-top: 12px;
  border: 1px solid #e5e7df;
  .break_table_header {
    background-color: #f9faf5;
    color: #102c42;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border: 1px solid #e5e7df;
    font-size: 12px;
    line-height: 14px;
  }

  .break_table_cell {
    text-align: center;
    padding: 10px;
    border: 1px solid #e5e7df;
    font-size: 12px;
    line-height: 14px;
    background-color: white;
  }
}

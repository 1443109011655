.leave-form-wrapper {
  padding: 25px 70px 34px;
  @media screen and (max-width: 991px) {
    padding: 16px;
  }
  .edit_timesheet_width {
    max-width: 60%;
    margin-top: 45px;
    @media screen and (max-width: 991px) {
      max-width: 100%;
      margin: 0;
    }
  }
}

.timesheet-calendar-wrapper {
  margin-top: 44px;

  @media screen and (max-width: 767px) {
    padding: 20px 15px;
    margin-top: 30px;

    .link-btn-font {
      font-size: 13px;
    }
  }

  .calendar-col {
    @media screen and (min-width: 992px) {
      padding-right: 70px;
      border-right: 1px solid #e0e0e0;
    }
    .calendar-header {
      display: flex;
      justify-content: space-between;
    }
    .hour-box {
      font-size: 15px;
      font-weight: 600;
      text-align: right;
      color: #102c42;
      margin-bottom: 10px;
    }
    .legend-wrapper {
      margin-top: 10px;
      padding-top: 20px;
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      border-top: 1px solid rgba(177, 199, 152, 0.3);
      @media screen and (max-width: 991px) {
        margin-bottom: 20px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 20px;
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          display: flex;
          margin-bottom: 10px;
          &.width-100 {
            width: 100%;
          }
          .legend-icon {
            width: 12px;
            height: 12px;
            background: #66accf;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            margin-right: 5px;
            &.current-color {
              background: #66accf;
              border-color: #66accf;
            }
            &.holiday-color {
              background: #587e85;
            }
            &.leaves-color {
              background: #ffba00;
            }
            &.non-working-color {
              background: #fff;
              border-color: #e5e7df;
            }
            &.pending-color {
              background: #fff;
              border-color: #ff5757;
            }
            &.added-color {
              background: #fff;
              border-color: #66accf;
            }
          }

          .text-box {
            font-weight: 400;
            font-size: 10px;
            line-height: 1.2;
            color: #87928d;
          }
        }
      }
    }
  }

  .timesheet-box {
    @media screen and (min-width: 992px) {
      padding-left: 70px;
      .alert-icon {
        position: relative;
        top: 4px;
      }
    }
    @media screen and (max-width: 992px) {
      margin-top: 10px;
    }
  }

  .date-status-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    .date-box {
      color: #111b45;
      font-size: 25px;
      font-weight: 500;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        order: 2;
        margin-top: 20px;
      }
    }

    .status-box {
      border-radius: 6px;
      color: #fff;
      padding: 5px 8px 4px;
      font-size: 12px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }

  .timesheet-form {
    label {
      color: #79869a;
      font-size: 13px;
      margin: 0;
    }

    .form-control {
      border: 0;
      border-bottom: 1px solid #d8d8d8;
      padding: 5px 0 15px;
      font-weight: 700;
      color: #102c42;
      font-size: 14px;
      box-shadow: none;
      outline: 0;
      &::placeholder {
        color: #102c42;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #102c42;
      }
      &::-ms-input-placeholder {
        color: #102c42;
      }
    }

    .btn {
      position: static;
      width: auto;
      transform: none;
      border-radius: 30px;
      border: 2px solid transparent;
      padding: 12px 35px;
      box-shadow: none;
      outline: 0;
      transition: 0.5s;
      margin-bottom: 20px;
      font-size: 15px;
      height: 52px;
      line-height: initial;
      @media screen and (max-width: 1090px) {
        margin-right: 15px !important;
        font-size: 14px;
        padding: 12px 25px;
      }
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
      }

      &.btn-outline-secondary {
        color: #587e85;
        border-color: #587e85;
        background-color: transparent;

        &:hover,
        &:focus {
          color: #fff;
          background: #587e85;
        }

        &:disabled {
          background: #eee;
          border: 1px solid #ddd;
          text-shadow: 0 1px 1px white !important;
          color: #ccc !important;
          cursor: default !important;
          appearance: none !important;
          box-shadow: none !important;
          opacity: 0.8 !important;
        }

        @media screen and (max-width: 767px) {
          border-color: transparent;
          width: auto;
          padding: 0;
          line-height: 1;
          height: auto;
          border-radius: 0;
          border-bottom: 2px solid #587e85;
          margin: 30px auto 10px !important;
          background-color: none;

          &:hover,
          &:focus {
            color: #587e85;
            background-color: transparent;
          }

          &:disabled {
            background: unset;
            border: none;
          }
        }
      }
    }

    .link-btn-box {
      padding: 14px 0 14px 25px;
      border-left: 1px solid #e0e0e0;
      display: inline-block;
      @media screen and (max-width: 767px) {
        margin: 10px auto;
        display: block;
        padding: 5px;
        max-width: 120px;
      }
    }
  }

  .applicable-box {
    max-width: 340px;
    margin: 70px auto 10px;
    text-align: center;
    color: #535b5f;
    font-size: 16px;
    font-weight: 300;
    h4 {
      color: #102c42;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 991px) {
      margin-top: 0;
    }
  }
}

.pending {
  abbr {
    border: 1px solid yellow;
    background-color: yellow;
    color: white;
  }
}

.timesheet-filled {
  background-color: var(--color-timesheet-filled);
}

.timesheet-red {
  background-color: var(--color-timesheet-red);
}

.timesheet-paid {
  background-color: var(--color-timesheet-paid);
}

.loader-position {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2px;
  margin: 8px;

  @media (max-width: 767px) {
    right: unset;
    left: 0;
    margin-left: 15px;
    margin-top: 20px;
  }
}

.subtext-position {
  position: absolute;
  top: 40px;
  left: 0;

  @media (max-width: 767px) {
    top: 45px;
  }
}

.reason-label {
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
}

.time-picker {
  input {
    border: none;
    border-bottom: 1px solid #d8d8d8;
    padding: 5px 0 15px;
    font-weight: 700;
    color: #102c42;
    font-size: 14px;
    box-shadow: none;
    outline: 0;
    border-radius: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  input:disabled {
    background: var(--color-white);
    color: #102c42;
    cursor: text;
  }
}

.time-picker-popup {
  input {
    font-weight: 700;
    color: #102c42;
    font-size: 14px;
  }
}

.clock-list-timesheet {
  margin-bottom: 30px;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  li {
    border-bottom: 1px solid #e7eee0;
    display: flex;
    padding: 10px 0;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #2f3245;

    input {
      max-width: 120px;
      &:focus {
        border-color: #a9cf3d;
      }
    }
    &:last-child {
      border: 0;
    }
    .btn-box {
      padding: 7px 7px 6px 4px;
      border-radius: 26.1px;
      background-color: #f9fcee;
      min-width: 170px;
      margin-right: 30px;
      font-size: 13px;
      font-weight: 600;
      color: #2f3245;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
        margin-bottom: -7px;
      }
      &.btn-break {
        background-color: rgba(243, 164, 20, 0.05);
      }
      &.btn-clickout {
        background-color: rgba(238, 79, 79, 0.05);
      }
      &.disabled {
        background: #eee;
        color: #ccc !important;
        cursor: default !important;
        appearance: none !important;
        opacity: 0.8 !important;
      }
    }
  }
}
.sub-head {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 30px;
}
.heading {
  margin-top: -16px;
  @media (max-width: 767px) {
    margin-top: -8px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 18px;
  }
}
.text-wrap {
  word-break: break-word;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-weight: 400;
}
